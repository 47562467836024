.nfc-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}
.nfc-content {
  width: 100%;
  height: 100vh;
}
.nfc-preview {
  width: 30vw;
  height: 100vh;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.nfc-preview-img {
  object-fit: contain;
  height: 100vh;
}
.nfc-banner-img {
  width: 120px;
}
.nfc-banner {
  height: 30vh;
  width: 100%;
  background: url("https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669591016/bg-banner.c5fc04217b35f5d84294_fdeoly.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.nfc-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.nfc-nav-active {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
.nfc-nav-passive {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.6;
}
.nfc-con {
  height: 70vh;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
}
.nfc-con-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nfc-con-flex-heading {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
}
.nfc-con-flex-link-txt {
  border: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  text-transform: lowercase;
  color: #000000;
}
.nfc-con-flex-link-txt:focus-visible {
  outline: none;
}
.nfc-con-flex-link-txt-button {
  text-align: center;
  cursor: pointer;
  background: black;
  border-radius: 34px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 8px 32px;
  margin-left: 50px;
}
.nfc-con-vert-flex {
  display: flex;
  gap: 50px;
}
.nfc-con-vert-flex-a {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nfc-con-vert-flex-a-header {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
}
.nfc-con-vert-flex-a-img {
  width: 66px;
}
.nfc-con-vert-flex-a-img-upload-txt {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  text-decoration-line: underline;

  color: rgb(127, 127, 128);
  
  position: absolute;
  width: 66px;
  height: 66px;
  cursor: pointer;
  opacity: 0;

}
.nfc-con-vert-flex-a-img-upload {
  display: flex;
  gap: 15px;
  align-items: center;
}
.nfc-con-vert-flex-a-input {
  border: none;
  outline: none;
  border-bottom: 1px solid #cccccc;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #7f7f80;
  padding: 8px 0px;
  width: 325px;
  color: black;
}
.nfc-con-btn {
  cursor: pointer;
  background: #000000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 16px 100px;
  align-self: center;
  margin-top: 35px;
}
.nfc-con-flex-2 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.nfc-con-vert-flex-a-input-2 {
  border: none;
  outline: none;
  border-bottom: 1px solid #cccccc;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-transform: capitalize;
  color: red;
  width: max-content;
}
.nfc-con-vert-flex-a-input-1 {
  border: none;
  outline: none;
  border-bottom: 1px solid #cccccc;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-transform: capitalize;
  color: #7f7f80;
  width: max-content;

  line-height: 13px;
  text-transform: capitalize;
  color: #7f7f80;
}
@media screen and (max-width: 1000px) {
  .nfc-preview {
    display: none;
  }
  .nfc-banner {
    width: 100vw;
  }
}
@media screen and (max-width: 860px) {
  .nfc-con-vert-flex {
    flex-direction: column;
  }
  .nfc-con {
    height: auto
  }
  .nfc-content {
    height: auto
  }
  .nfc-page {
    height: auto
  }
}
@media screen and (max-width: 690px) {
  .nfc-con {
    padding: 40px !important;
  }
  .nfc-con-flex-link-txt-dis {
    display: none;
  }
  .nfc-con-flex-link-txt-button {
    margin-left: 10px;
  }
  .nfc-con-vert-flex-a-input {
    width: auto;
  }
}
@media screen and (max-width: 500px) {
  .nfc-nav {
    gap: 15px;
  }
  .nfc-nav-active {
    font-size: 12px;
  }
  .nfc-nav-passive {
    font-size: 12px;
  }
}
.imageLabel {
  font-family: 'Montserrat';
  color: rgb(127, 127, 128);
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.react-multi-carousel-list.carousel-container {
  width: calc(100vw - 655px);
}
input:disabled {
  background-color: transparent;
}