*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: "Montserrat";
  src: url("./Gotham-Font/Gotham-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Montserrat";
  src: url("./Gotham-Font/GothamMedium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url("./Gotham-Font/GothamLight.ttf") format("truetype");
}

.hover {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button {  
  display: none;
}

.previewButton {
  background-color: white;
  color: black;
  padding: 14px 28px;
  border-radius: 60px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-top: 15px;
}

@media screen and (max-width:1400px) {
  .nfc-preview {
    display: none !important;
  }
  .nfc-con {
    overflow: auto;
  }
}

@media screen and (min-width:1400px) {
  .previewButtonDisplay {
    display: none !important;
  }
}

input::placeholder {
  color: rgb(127, 127, 128);
  opacity: 1;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(127, 127, 128);
  opacity: 1;
 }
 
 input::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(127, 127, 128);
  opacity: 1;
 }