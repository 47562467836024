.page {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .page-content {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 55px;
    align-items: center;
    justify-content: center;
    background: #202125;
  }
  .page-img {
    width: 50vw;
    height: 100vh;
  }
  .page-img-img {
    width: 100%;
    height: 100%;
  }
  .page-title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-transform: capitalize;
    color: #f8f8f8;
  }
  .page-subtitle {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    color: #f8f8f8;
  }
  .page-inputs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    justify-content: center;
  }
  .page-input {
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #cccccc;
    color: #cccccc;
    width: 440px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    padding: 8px;
  }
  .page-subtitle-2 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #f8f8f8;
    margin-top: 35px;
  }
  .button {
    margin-top: 25px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #ffffff;
    padding: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    width: 440px;
    cursor: pointer;
  }
  .page-logo {
    width: 144px;
  }
  .page-subtitle--2 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #f8f8f8;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .forgot-pass {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    text-transform: capitalize;
    color: #cccccc;
    align-self: flex-end;
    cursor: pointer;
  }
  .mob-signin{
    display: none;
  }
  .mob-signup{
    display: none;
  }
  @media screen and (max-width: 1000px) {
    .page {
      flex-direction: column-reverse;
    }
    .page-content {
      width: 100vw;
      gap: 45px;
      height: 74vh;
    }
    .page-img {
      width: 100vw;
      height: 40vh;
    }
    .page-img-img{
      object-fit: cover;
    }
    .page-input {
      width: calc(100vw - 60px);
    }
    .button {
      width: calc(100vw - 60px);
      margin-top: 0;
    }
    .page-subtitle-2 {
      width: calc(100vw - 60px);
      line-height: 20px;
      margin-top: 0px;
    }
    .page-img {
      position: relative;
    }
    .mob-signin{
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100vw;
      top: 0;
      height: 100%
    }
    .mob-signup{
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
    .log{
      display: none;
    }
    .signin-page{
      height: 60vh;
    }
  }
  @media screen and (max-width: 1400px) {
    .page {
      padding: 60px 60px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .page {
      padding: 0 !important;
    }
  }
  @media screen and (max-width: 1000px) {
    .page {
      height: auto;
    }
    .marginminus {
      margin-bottom: 30px;
    }
    .page-img {
      height: 300px !important;
  }
    .page-img22 {
      height: 400px !important;
  }
  .mobile-img-signup {
    display: block !important;
  }
  .dekstop-img-signup {
    display: none !important;
  }
  .marginMobile {
    margin-top: 25px;
  }
  .page-content {
    gap: 20px !important;
  }
  .page-subtitle {
    line-height: 20px;
  }
  }
  @media screen and (max-width: 650px) {
  .rp-page-box {
    padding: 0px !important;
    padding-top: 80px !important;
  }
  .signupheading {
    margin-top: 30px !important;
    width: calc(100vw - 60px)
  }
  }
  @media screen and (max-width: 500px) {
  .page-content {
    margin-top: -20px !important;
  }
  }
  @media screen and (max-width: 400px) {
  .mobile-img-signin {
    margin-bottom: 45px !important;
  }
  }
.marginminus {
  margin-top: -5px !important;
  width: auto;
}
