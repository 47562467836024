.profile-page {
  width: 100vw;
  height: 100vh;
  background: #000;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 40px;
  overflow: auto;
}
.profile-page::-webkit-scrollbar {
  display: none;
}
.profile-page::-webkit-scrollbar-track {
  display: none;
}
.profile-page-banner {
  width: 100%;
}
.profile-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -80px;
}
.profile-page-dp {
  width: 128px;
  height: 128px;
  border-radius: 100%;
}
.profile-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 34px;
  /* identical to box height */
  color: #ffffff;
  text-align: center;
  max-width: 480px;
  margin-left: 25px;
  margin-right: 25px;
}
.profile-subtitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  margin-top: -5px;
  max-width: 480px;
  margin-left: 25px;
  margin-right: 25px;
}
.profile-button-main {
  margin-left: 25px;
  margin-right: 25px;
  background: #0c0c0c;
  border-radius: 34px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #ffffff;
  padding: 34px;
  max-width: 520px;
  min-width: 420px;
  line-height: 20px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.profile-button-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 182px;
}
.profile-button-secondary {
  width: 65px;
}
.profile-socials-flex {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-top: 8px;
}
.profile-social {
  width: 32px;
}
.mobile-reverse {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width:500px) {
  .mobile-reverse {
    flex-direction: column-reverse;
  }
  .profile-socials-flex {
    margin-top: 0;
    gap: 24px
  }
  .profile-button-flex {
    margin-top: 8px;
  }
  .profile-button-main {
    min-width: 320px
  }
}
@media screen and (max-width:350px) {
  .profile-button-main {
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  .profile-socials-flex {
    gap: 18px
  }
  .profile-title {
    margin-right: 10px;
    margin-left: 10px;
  }
  .profile-subtitle {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (max-width: 700px) {
  .profile-socials-flex {
    max-width: 250px;
    flex-wrap: wrap;
    justify-content: center;
  }
}