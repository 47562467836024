.rp-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.rp-page-box {
  width: 600px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  background-color: #202125;
}

.ps {
  margin-top: 80px;
  text-align: left;
  width: 440px;
}
.pi {
  margin-top: 60px;
}
.pi2 {
  margin-top: 25px;
}
.bt {
  margin-top: 80px !important;
}
@media screen and (max-width: 1000px) {
  .bt {
    width: 440px !important;
  }
  .pi {
    width: 440px !important;
  }
  .pi2 {
    width: 440px !important;
  }
}
@media screen and (max-width: 640px) {
  .rp-page-box {
    width: calc(100vw - 40px);
  }
  .ps {
    width: calc(100vw - 80px) !important;
  }
  .bt {
    width: calc(100vw - 80px) !important;
  }
  .pi {
    width: calc(100vw - 80px) !important;
  }
  .page-input.pitu {
    width: calc(100vw - 80px) !important;
  }
  .pi2 {
    width: calc(100vw - 80px) !important;
  }
  .pt {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 370px) {
  .successMobile {
    line-height: 20px !important;
    bottom: -50px !important;
  }
}

.mt100 {
  margin-top: 100px !important;
}
